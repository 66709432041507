<template>
  <div class="container">
    <div class="row mb-5">
      <div class="col-12">
        <h2>Terms And Conditions</h2>
        <ol>
          <li><strong>PREAMBLE</strong></li>
          <p>
            Excelerate believes that passionate academics are the future. Therefore,
            Excelerate has built Excelerate.dk (the “Platform”) that connects
            students with professionals in order to support thesis collaboration.
            The following general terms and conditions (“GTC”) apply to the
            provision and use of the Platform and any services provided by
            Excelerate outside the Platform.
            <br />
            By actively agreeing to be bound by these GTC, the company (“Customer”),
            as represented by you (”User”), unconditionally consent to be bound by
            these GTC that constitutes an agreement between Excelerate and Customer
            together with the terms applicable for any subscription services
            (“Subscription Terms”) opted by Customer (together the “Agreement”),
            with Subscription Terms, if any, taking precedence. No other
            conflicting, contrary or additional terms and conditions shall be deemed
            accepted by Excelerate unless and until we expressly confirm our
            acceptance hereof in writing. The Agreement constitutes the entire
            agreement and understanding of the Parties and supersedes any previous
            agreement between the Parties relating to the subject matter of the
            Agreement.
            <br />
            Each of the Parties acknowledges and agrees that in entering into the
            Agreement with these, they do not rely on any statement, representation,
            warranty or understanding (whether negligently or innocently made) of
            any person (whether party to this agreement or not) other than as
            expressly set out in the Agreement with these GTC.
            <br />
            The User represents and warrants that it has the legal power and
            authority to accept these GTC and to enter into the Agreement with
            Excelerate.
            <br />
            If Customer does not unconditionally agree to all terms of these GTC or
            want to become a party to the Agreement, then, unless expressly approved
            otherwise by Excelerate, any access or use of the Platform and
            Excelerate’s services is strictly prohibited.
            <br />
            Excelerate reserve the right to amend these GTC at any time or terminate
            the Agreement and, consequently, any access or use by User and Customer
            of the Platform and Excelerate’s services.
          </p>
          <li>
            <strong>
              DEFINITIONS</strong>
            <ol>
              <li>
                <strong>“Confidential Information”</strong> has the meaning set out
                in section
              </li>
              <li>
                <strong>“Customer”</strong> means the legal entity subject to these
                GTC and who is represented by the User.
              </li>
              <li>
                <strong>“Customer Data”</strong> means any data not considered User
                Data that is owned by Customer and provided to Excelerate or the
                Platform by or on behalf of Customer. For the avoidance of doubt,
                this does not include data generated by Excelerate or the Platform
                from the User’s use.
              </li>
              <li>
                <strong>“Effective Date”</strong> means the date when the User has
                completed the required registration process entitling the Customer
                to use the Platform.
              </li>
              <li>
                <strong>“Excelerate Data”</strong> means any data or information,
                including Usage Data, not considered User Data or Customer Data
                stored, submitted or otherwise accessible on or through the Platform
                or such data that would otherwise by its nature be considered
                Excelerate’s data or information.
              </li>
              <li>
                <strong>“Excelerate”</strong> means Excelerate ApS with company
                registration number 41409002 and registered address Strandlodsvej
                44, 3. 2300 Copenhagen S.
              </li>
              <li>
                <strong>“Party”</strong> or <strong>“Parties”</strong> means
                Customer and Excelerate individually or jointly.
              </li>
              <li>
                <strong>“Third-Party Material”</strong> means any third-party
                content and materials.
              </li>
              <li>
                <strong>“Usage Data”</strong> means any data not considered User
                Data or Customer data that is generated by Excelerate or the
                Platform due to the User’s use of the Platform or Excelerate’s
                services. For the avoidance of doubt, this includes metric or
                technical data or other data generated by the Platform from User’s
                interaction with the Platform.
              </li>
              <li>
                <strong>“User”</strong> means the person that uses the Platform on
                behalf of Customer.
              </li>
              <li>
                <strong>“User Data”</strong> means any data deemed personal data in
                accordance with applicable data protection regulations that is
                collected by or on behalf of Excelerate from the User or from the
                use of the Platform or Excelerate’s services.
              </li>
            </ol>
          </li>
          <li>
            <strong>
              THE PLATFORM</strong>
            <ol>
              <li>
                Excelerate grants the Customer a non-exclusive, non-transferable,
                non-sub- licensable, worldwide, license to access and use the
                Platform for Customer’s internal use in accordance with these GTC.
              </li>
              <li>
                Unless explicitly permitted herein or by Excelerate’s written
                approval, Customer may not sell, resell, rent, assign or share the
                Platform with any third party. Excelerate reserves all rights to the
                Platform not expressly granted to Customer herein.
              </li>
              <li>
                Without granting any additional licenses hereunder, Customer may
                authorize its contractors to use or operate the Platform solely on
                Customer’s behalf and provided that Customer obtains such third
                parties’ binding consent in advance to abide by the terms of these
                GTC and provided Customer shall be responsible for such parties’ use
                and compliance. Such parties are not, and shall not be deemed to be,
                third party beneficiaries hereunder or for any other reason.
              </li>
              <li>
                Excelerate reserves the right to make changes and updates from time
                to time to the functionality of the Platform provided to Customer,
                provided that such changes do not have a material adverse effect on
                the functionality of the Platform.
              </li>
              <li>
                Customer is only permitted to use the Platform as provided and
                intended by Excelerate and may not decompile, reverse engineer,
                disassemble or otherwise attempt to derive and/or gain access to
                source code from any software made available to Customer as part of
                the Platform. The User shall neither use nor permit others to use or
                access the Platform to, (a) build a competitive product or
                service,(b) make or have made a product using similar ideas,
                features, functions or graphics of the Platform, (c) make derivative
                works based upon the Platform, or (d) copy any features, functions
                or graphics of the Platform.
              </li>
              <li>
                Customer shall neither use nor permit others to use the Platform for
                any unlawful, invasive, infringing, defamatory, fraudulent, or
                obscene purpose.
              </li>
              <li>
                Unless explicitly undertaken by Excelerate, Excelerate is not
                responsible for Customer’s use of the Platform.
              </li>
              <li>
                Customer is responsible for all activities that occur during the
                User’s use of the Platform, or by any other users designated by
                Customer.
              </li>
              <li>
                Customer acknowledges and agrees that the provision of the Platform
                is subject Third-Party Services that may from time to time adversely
                affect the provision of the Platform in terms of availability. For
                the avoidance of doubt, Excelerate does not warrant any
                availability.
              </li>
            </ol>
          </li>
          <li>
            <strong>
              ACCESS TO THE PLATFORM AND USE</strong>
            <ol>
              <li>
                For User to be able to use the Platform on behalf of Customer, the
                User will be provided with login from Excelerate.
              </li>
              <li>
                Customers designated user(s)’ login(s) are strictly confidential and
                may only to be used by the designated user. Customer is responsible
                for all activities that occur during the User’s use of the Service.
                The User and Customer agrees to immediately notify Excelerate of any
                unauthorized use of the Platform, usernames or passwords or account
                or any other known or suspected breach of security.
              </li>
              <li>
                Where a contact between a student and Customer has been made
                possible due to the Platform or Excelerate’s services, Customer
                agree to use the Platform as intended and shall not bypass the
                Platform during any engagement between Customer and the student
                where such engagement is supported by the Platform. In particular,
                Customer agree to confirm any interviews or thesis collaborations
                with a student on the Platform. In no event shall Customer be
                allowed to commence a thesis collaboration with a student until
                confirmation hereof on the Platform. Customer agrees to pay
                Excelerate a fee of EUR 2.000 for any failure to comply with this
                clause 4.3.
              </li>
            </ol>
          </li>
          <li>
            <strong>
              PRICES, FEES AND PAYMENT</strong>
            <ol>
              <li>
                All price, fees or otherwise are exclusive taxes or charges imposed
                by any governmental authority.
              </li>
              <li>
                Unless otherwise expressly stated in the order confirmation, payment
                shall be made within 30 days from issuance of the invoice without
                any right to offset or deduction.
              </li>
              <li>
                If Customer fails to pay any invoice within seven (7) calendar days
                from the due date of payment, Excelerate may suspend the Agreement
                until payment is made. After thirty calendar days of suspension,
                Excelerate may terminate the Agreement, including any delivery and
                deactivate Customer’s designated users without any refund or
                liability. Further, Excelerate may charge Customer an interest rate
                of onepoint five (1,5) percent per month from the due date to the
                date of payment without any notice. This shall be in addition to,
                and not limited by, any other rights or remedies to which Excelerate
                may be entitled according to law or in equity. If any suspension or
                termination is made, Excelerate must provide a written notice hereof
                to the Customer within seven (7) calendar days from the date where
                the suspension or termination is made.
              </li>
            </ol>
          </li>
          <li>
            <strong>
              SUPPORT</strong>
            <ol>
              <li>
                Support services for the Platform and other matters relating to use
                of the Platform shall be provided in or via the Platform or as
                otherwise directed by Excelerate.
              </li>
            </ol>
          </li>
          <li>
            <strong>
              OWNERSHIP AND INTELLECTUAL PROPERTY RIGHTS</strong>
            <ol>
              <li>
                Save for 7.2-7.3, all intellectual property rights in or arising out
                of or in connection with the Platform or Excelerate’s services,
                including Usage Data, shall be owned by Excelerate. Excelerate and,
                if applicable, its licensors, retain all rights, title and interest
                in and to the Platform, Excelerate Data, Third-Party Material and
                Third- Party Services, including but not limited to any images,
                photographs, animations, video, audio, music, text, applets
                incorporated into the Platform.
              </li>
              <li>
                The Agreement grants no ownership rights in the Platform and
                Excelerate Data to Customer.
              </li>
              <li>
                The User shall own and shall continue to own all User Data and
                Customer shall own and continue to own all Customer Data.
              </li>
            </ol>
          </li>
          <li>
            <strong>THE CUSTOMER DATA</strong>
            <ol>
              <li>Customer shall remain owner of Customer Data. Customer is solely responsible for
                the accuracy, quality, integrity, legality, reliability, appropriateness and the
                rights
                to all Customer Data.</li>
              <li>The Customer shall not provide any data that constitutes confidential information,
                to the effect that Excelerate shall be justified to treat such data from the User as
                non-confidential information.</li>
              <li>Customer grants to Excelerate and its Affiliates a non-exclusive licence to use,
                copy,
                store, transmit, disclose and display Customer Data in order to provide and
                maintain the Platform and its services as agreed in this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong>USER DATA AND PRIVACY</strong>
            <ol>
              <li>The data protection obligations (in particular the EU General Data Protection
                Regulation 2016/679 (GDPR) and all other applicable data protection laws) shall be
                observed. Excelerate processes personal data. In doing so, Excelerate has
                implemented appropriate technical and organizational measures which meet the
                requirements of applicable data protection law, in particular the GDPR and these
                general terms and conditions.</li>
              <li>Excelerate may aggregate anonymous statistical data regarding use and functioning
                of its system by its various users, including the User. Such aggregated
                statisticaldata will be the sole property of Excelerate. Excelerate will use
                commercially
                reasonable security measures to protect User Data against unauthorized disclosure
                or use. The Excelerate’s privacy policies in effect from time to time are located at
                https://www.excelerate.dk/privacy-notice</li>
            </ol>
          </li>
          <li>
            <strong>WARRANTY AND WARRANTY DISCLAIMERS</strong>
            <ol>
              <li>Except as stated herein, the Platform and Excelerate Data are provided to Customer
                on an “as is” and “as available” basis. Customer assumes all responsibility for
                determining whether the services or the information generated thereby is accurate
                or sufficient for Customer’s purposes.</li>
              <li>Excelerate warrants that; (a) the Platform provided to Customer is and will be
                completed in a professional, workmanlike manner, with the degree of skill and care
                that is required by good, and sound professional procedures, and shall be
                completed in accordance with the Agreement; (b) the Platform do not
                misappropriate, violate or infringe any copyright, trademark, mask work, trade
                secret, patent or other intellectual property or proprietary right of others; and (c)
                Excelerate has full power to grant the rights granted to Customer under these GTC.
              </li>
              <li>Excelerate does not warrant that use of the Platform will be error-free or
                uninterrupted. For any material defective or non-conforming portion of the
                Platform covered by this warranty, Excelerate shall upon the Customer’s notice of
                such non-conformity or defective, remedy the non-conforming or defective portion
                if commercially practicable. Any notice of any non-conformity or defective by
                Customer to Excelerate must be in writing and within 30 days after Customer’s first
                encounter any such non-conformity. In the event Excelerate does not deem it
                commercially practicable to remedy the non-confirming or defective portion, the
                Customer may choose to continue with the Platform without any further effect or
                terminate the Agreement. Any prepaid and unused services will be refunded to
                Customer.</li>
              <li>The warranties expressly stated in this Agreement are the sole and exclusive
                warranties offered by Excelerate. There are no other warranties of any kind,
                express or implied, Excelerate expressly disclaims any and all warranties of title,
                merchantability, fitness for a particular purpose or accuracy.</li>
              <li>The Platform may include Third-Party Service and Third-Party Materials. Excelerate
                does not supply and is not responsible for any Third-Party Service or Third-Party
                Materials, which may be subject to their own licenses, end-user agreements,
                privacy and security policies, and/or terms of use. Excelerate makes no warranty to
                and has no liability for Third-Party Service and Third-Party Material.</li>
            </ol>
          </li>
          <li>
            <strong>INDEMNIFICATION</strong>
            <ol>
              <li>Excelerate will, at its expense and at Customer’s request, defend, indemnify and
                hold harmless the Customer and its officers, directors, employees from and against
                any and all claims, actions, demands, liabilities, settlements, costs, damages and
                fees (including attorneys’ and other professionals’ fees and costs) arising, in whole
                or in part, in connection with a claim, suit, action, or proceeding by a third
                partyalleging that any portion of the Platform (excluding Customer Data but including
                Excelerate Data) misappropriates, violates or infringes any third party’s patent,
                copyright, trademark, trade secret, or other intellectual property or proprietary
                right.</li>
              <li>Customer will, at its expense and at Excelerate’s request, defend, indemnify and
                hold harmless the Service Provider and its contractors, officers, directors,
                employees from and against any and all claims, actions, demands, liabilities,
                settlements, costs, damages and fees (including attorneys’ and other professionals’
                fees and costs) arising, in whole or in part, in connection with a claim, suit,
                action,
                or proceeding by a third party alleging that Customer Data or information supplied
                by Customer infringes the intellectual property rights or other rights of a third
                party
                or has caused harm to a third party.</li>
              <li>In case of any claim that is subject to indemnification as set forth herein, the
                Party
                that is indemnified (Indemnitee) will provide the indemnifying Party (Indemnitor)
                reasonably prompt notice of the relevant claim. Indemnitor will defend and/or
                settle, at its own expense, any demand, action, or suit on any claim subject to
                indemnification as set forth herein. Each Party will cooperate in good faith with the
                other to facilitate the defence of any such claim and will tender the defence and
                settlement of any action or proceeding covered by this section to the Indemnitor
                upon request. Claims may be settled without the consent of the Indemnitee, unless
                the settlement includes an admission of wrongdoing, fault or liability.</li>
              <li>
                . Each Party shall, in order not to lose its right to claim damages, put forward such
                claim no later than 30 days from the time when the Party became aware or should
                have become aware of the ground for the claim.
              </li>
            </ol>
          </li>
          <li>
            <strong>LIMITATION OF LIABILITY</strong>
            <ol>
              <li>
                Excelerate shall not be liable to Customer for any loss of profit, loss of use, loss
                of
                production, lost revenues, lost business or for any financial or economic loss or for
                any indirect or consequential damages whatsoever. Further, Excelerate cannot be
                held liable for any acts or omissions by a student, including any failure by a student
                to comply with any thesis collaborations agreed with Customer.
              </li>
              <li>
                Excelerate’s sole, exclusive and maximum liability for damages under the
                Agreement shall in aggregate be limited to the fees paid to Excelerate under the
                Agreement during the twelve (12) months preceding the claim.
              </li>
              <li>
                Nothing in this contract shall limit or exclude the Excelerate liability for: (A)
                death
                or personal injury caused by its negligence, (B) fraud or fraudulent
                misrepresentation, (C) gross negligence or willful misconduct; and/or (D) any other
                liability that cannot be excluded by law.
              </li>
              <li>
                Excelerate does not accept liability for Third-Party Services and Third-Party Material
                including acts and omissions.
              </li>
            </ol>
          </li>
          <li>
            <strong>CONFIDENTIALITY</strong>
            <ol>
              <li> <strong>“Confidential Information”</strong> means any information that is disclosed
                by one Party
                (<strong>“Discloser”</strong>) to the other (<strong>“Recipient”</strong>), which, at
                the time it is disclosed, in any
                form, is identified or designated by Discloser as “confidential or proprietary” or
                reasonably should be known by Recipient to be proprietary or confidential
                information of Discloser.</li>
              <li>The Recipient shall not use or disclose the Discloser’s Confidential Information
                without the prior written consent of the Discloser, except; (a) as specifically
                permitted by the Discloser; or (b) for the purpose of performing its obligations or
                enforcing its rights under the Agreement, provided that such disclosures are made
                only to those employees, consultants, contractors, professional advisors or third
                party service providers with a direct business need to know and who have agreed
                in writing to confidentiality provisions that provide the Discloser with at least as
                much protection as those contained herein.</li>
              <li>Confidential Information will exclude information that; (a) the Recipient can
                demonstrate to have had rightfully in its possession prior to disclosure to the
                Recipient by the Discloser; (b) is now or subsequently becomes available to the
                public through no wrongful act of the Recipient; (c) has been rightfully received by
                the Recipient from a third party who has the right to transfer or disclose it to the
                Recipient without restriction on disclosure; (d) has been independently developed
                by the Recipient without the use of any Confidential Information as evidenced by
                appropriate documentation; or (e) has been approved for release by written
                authorization executed by an authorized officer of the Discloser. Notwithstanding
                the foregoing, if the Recipient is required to disclose Confidential Information
                pursuant to a court order or other requirement of applicable law, the Recipient
                shall provide the Discloser with prompt written notice of any such requirement
                sufficient to permit the Discloser to seek and obtain appropriate protective orders
                prior to such disclosure by the Recipient. All Confidential Information remains the
                property of the Discloser and no license or other rights in the Confidential
                Information is granted hereby.</li>
              <li>
                All information provided hereunder is provided ‘as is’ and without any warranty,
                express, implied, or otherwise, regarding its accuracy or performance. At any time
                at the request and choice of the Discloser, the Recipient will either return to the
                Discloser or destroy all the Discloser’s Confidential Information, in whatever form,
                which is in its custody or control.
              </li>
            </ol>
          </li>
          <li>
            <strong>TERM AND TERMINATION</strong>
            <ol>
              <li>
                The Agreement takes effect and commences on the Effective Date and shall, unless
                the Parties agree otherwise or for specific subscription terms, continue to apply.
                Save for an active subscription, Customer may terminate by deleting all its
                designated Users. And Excelerate may terminate by written notice to the Customer,
                e.g. by written e-mail to the User.
              </li>
              <li>Notwithstanding any subscription, either Party may terminate if the other Party: (1)
                materially breaches the Agreement, (2) infringes the other Party’s or other third
                party’s intellectual property rights; or (3) enter into liquidation either voluntary
                orcompulsory or become insolvent or enter into composition or corporate
                reorganisation proceeding.</li>
            </ol>
          </li>
          <li><strong>MISCELLANEOUS</strong>
            <ol>
              <li><strong>Force Majeure</strong><br>
                Neither Party will be deemed in breach, to the extent that performance of its
                obligations or attempts to cure any breach are delayed or prevented by reason of
                any event beyond the reasonable control of such Party, provided that the Party
                seeking to be excused gives the other Party written notice thereof promptly and, in
                any event, within 15 days of discovery thereof and uses its reasonable efforts to
                continue to so perform or cure. In the event of such a force majeure event, the time
                for performance or cure will be extended for a period equal to the duration of the
                force majeure event. If the period of delay or non-performance continues for in
                excess of 30 days, the party not affected may terminate the Agreement by giving
                written notice to the affected party.</li>
              <li><strong>Publicity</strong><br>Unless otherwise agreed in writing, both Parties may
                reasonably use, without the
                other Party’s prior written consent, the name and logo of the other party and any
                Customer Data provided through the Platform. In no event may such information
                be used in any way to damage, harm or otherwise bring the other Party publicly in
                miscredit. In such event, the Party claiming to be damaged, in harm or miscredit
                shall notify the other Party who shall without undue delay stop such use of the
                Party’s name, logo and Customer Data.</li>
              <li><strong>Governing law and venue</strong><br>The Agreement and the rights and
                obligations of the Parties pursuant thereto will
                be governed by the laws of Denmark. The Parties irrevocably agree that the
                Copenhagen City Court shall have exclusive jurisdiction in relation to any claim,
                dispute or difference concerning the Agreement, the Platform and any matter
                arising therefrom.</li>
              <li><strong>Notices</strong><br>Any notice required or permitted hereunder shall be in
                writing and may be given to
                each Party’s registered address or e-mail, or at such other address as the Party may
                hereafter specify in writing.</li>
              <li><strong>Survival</strong><br>All terms and provisions of the Agreement, including
                any and all exhibits, addenda
                and amendments hereto, which by their nature are intended to survive any
                termination or expiration, shall so survive.</li>
              <li><strong>Relationship of the Parties</strong><br>No employment relationship is
                created between the Parties. Nothing in the
                Agreement will be construed as creating a partnership, franchise, employment,
                joint venture or agency relationship of any kind between the Parties.</li>
              <li><strong>Independent development</strong><br>Provided there is no infringement of the
                other Party’s intellectual property rights
                or breach of a Party’s obligations of confidentiality, nothing in these General Terms
                and/or the Agreement will impair either Party’s right to develop, manufacture,
                purchase, use or market, directly or indirectly, alone or with others, products or
                services competitive with those offered by the other. The User is neither obligated
                to accept or purchase any product or service from the Service Provider at any time.
              </li>
              <li><strong>Modification</strong><br>Any waiver, modification or amendment of any
                provisions of this these GTC, the
                Agreement shall be effective only if in writing and signed by the Parties.</li>
              <li><strong>Severability</strong><br>If any provision of this Agreement shall be found
                by any court or administrative
                body of competent jurisdiction to be invalid or unenforceable, such invalidity or
                unenforceability shall not affect the other provisions of the Agreement which
                shall remain in full force and effect.</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>

  </div>
</template>

<script>

export default {


  data: function () {
    return {

    }
  }
}
</script>
<style scoped>
ol {
  counter-reset: item;
}

ol li {
  display: block;
  margin-bottom: 2%;
}

ol li:before {
  content: counters(item, ".")". ";
  counter-increment: item;
}
</style>
